import React from "react";
import Contact from "./Contact";
import About from "./About";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div>
      <Link to="/">Home</Link>
      <Link to="/About">About</Link>
      <Link to="/Contact">Contact</Link>
      <Home />
      <Contact />
      <About />
    </div>
  );
}

export default Home;
