// import Menu from './components1/Menu';
import {
  Routes,
  // Route,
  BrowserRouter,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
// import Contact from "./pages/Contact";
// import About from "./pages/About";
// import Contact from './pages/Contact';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes index element={<Home />} />
        <Routes path="/home" element={<Home />} />
      </BrowserRouter>
      {/* <Contact />
      <About /> */}
      <Home />

      {/* <Menu /> */}
    </div>
  );
}

export default App;
